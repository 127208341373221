import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const search = url.search;
  const hash = url.hash;
  const newURL = `https://dreamwave.ai${pathname}${search}${hash}`;

  const [timeLeft, setTimeLeft] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevState) => Math.max(prevState - 1, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = newURL;
    }, 2000);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div>Photo Lab Pro has renamed to Dreamwave AI!</div>
        <div>
          You will be automatically redirected to the new website in {timeLeft}{" "}
          seconds
        </div>
        <div>
          If you are not redirected, please click <a href={newURL}>here</a>
        </div>
      </header>
    </div>
  );
}

export default App;
